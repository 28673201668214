import { useContext, useEffect, useState} from 'react';
import { UserContext } from '../contexts/UserContext';
import defaultGuildIcon from '../assets/images/default-guild-icon.jpg';
import '../css/Guilds.css';

const Guilds = () => {
    const { user } = useContext(UserContext);
    const [guildsData, setGuildsData] = useState(null);
    const [error, setErrorData] = useState(null);

    useEffect(() => {
        const fetchGuildsData = async () => {
            try {
                const response = await fetch(`/api/guilds`);
                const data = await response.json();
                setGuildsData(data.guildsData);
            } catch (err) {
                setErrorData(err);
            }
        };
      
        fetchGuildsData();
    }, [user]);
    
    return (
        <>
            <h2>Aineko Guilds You Manage</h2>
            <div>
                {guildsData && guildsData.map((guild) => {
                    const icon = guild.icon 
                        ? `https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.png` 
                        : defaultGuildIcon;
                    return (
                        <a  key={guild.id} href={`/guild/${guild.id}`} className="guild">
                            <img src={icon} className="guild-icon" alt={`${guild.name} icon`}/>
                            <h3 className="guild-name">{guild.name}</h3>
                        </a>
                    )
                })}
            </div>
             <a href="https://discord.com/api/oauth2/authorize?client_id=1348015066322174035&permissions=268823632&scope=bot%20applications.commands">Add Aineko to your server.</a>
        </>
    );
};

export default Guilds;